import { useSearchParams } from "react-router-dom"
import Item from "../components/item";
import SearchBar from "../components/search_bar";
import { useState } from "react";


export default function Search(props) {
    const [searchParams] = useSearchParams();
    const key = searchParams.get("key");
    var keywords = ""
    if (key) {keywords = key.split(" ");}

    const getResults = (keywords) => {
    var results = []
    if (keywords.length>=1) {
        
        props.items.forEach(item=>{
            var matchingKeywords=0
            keywords.forEach(keyword=>{
                console.log(keywords)
                if (item.title.toLowerCase().includes(keyword.toLocaleLowerCase())){
                matchingKeywords+=1
                }
            })
            if (matchingKeywords==keywords.length) {
                results.push(item)
            }
        })

        
    }
    return results
}
    const results = getResults(keywords)
    return (<>
        <div className="container">
        <h1>{results.length} {(results.length==1) ? "Result" : "Results"} for '{key}'</h1>
        <div className="container">
            <SearchBar placeholder={props.items[Math.floor(Math.random()*props.items.length)].title} title="Search Again"></SearchBar>
        </div>

        <div className="sections">
            {results.sort(function (a, b) {
                        if (a.title.toLowerCase() < b.title.toLowerCase())
                          return -1;
                        if (a.title.toLowerCase() > b.title.toLowerCase())
                          return 1;
                        return 0;
                      }).map(item=>{
                return(<Item href={item[props.mode.link_key]} title={item.title} image={item.image} description={"$"+item.price}/>)
            })}
        </div>
      </div>
      </>
    )
}