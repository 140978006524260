import Item from "../components/item";
import ModeSwitcher from "../components/mode_switcher";

export default function Items(props){
    return(<div className="container">
        <h1>{props.title}</h1>
        <br/>
        <div className="sections">

            {props.items.sort(function (a, b) {
                        if (a.title.toLowerCase() < b.title.toLowerCase())
                          return -1;
                        if (a.title.toLowerCase() > b.title.toLowerCase())
                          return 1;
                        return 0;
                      }).map(item=>{
                return(<Item href={item[props.mode.link_key]} title={item.title} image={item.image} description={"$"+item.price}/>)
            })}
        </div>
      </div>)
}