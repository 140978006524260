import { useState } from "react";

export default function SearchBar(props) {
    const [inputValue, setInputValue] = useState("")

    const handleChange = (event) => {
        setInputValue(event.target.value)
    }

    const search = () => {
        const key = inputValue;
        window.location.href='/#/search?key='+encodeURI(key.trim())
    }

    return (
        <div className="container mobile-full-width">
            <h2>{props.title}</h2>
            <div className="flex-row flex search-container mobile-full-width">
            <input onKeyDown={(e)=>{
                if (e.key == "Enter") {
                    search()
                }
            }} type="text" placeholder={props.placeholder} onChange={handleChange} className="search-bar"/><button onClick={() => {search()}} className="btn-search"><span class="material-symbols-outlined">
search
</span></button>
            </div>
        </div>
    )
}