import ModeSwitcher from "../components/mode_switcher";
import Section from "../components/section";
import Discord from "../components/join_discord";
import SearchBar from "../components/search_bar";

export default function Home(props) {
 return (<div className="container">
    <h1>
      {props.contents.name} {`(${props.agent.display})`}
    </h1>
    <p className="description">{props.contents.description.split("\n").map((line)=>{return (<>{line}<br/></>)})}</p>
    <Discord discord={props.contents.discord}/>
    <SearchBar placeholder={props.items[Math.floor(Math.random()*props.items.length)].title} title="Search"/>

    <ModeSwitcher contents={props.contents} setAgent={props.setAgent} agent={props.agent}/>
    <div className="container">
    <h2>Sections</h2>
    <div class="accordion">
      <h2 className="accordion-header accordion-flush ">
      
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseSections"} aria-expanded="true" aria-controls={"collapseSections"}>
              SECTIONS
          </button>
          </h2>
          <div id={"collapseSections"} className="accordion-collapse collapse show ">
          <div className="accordion-body mobile-full-width padding-mobile">
          
        <div className="sections mobile-full-width">
          {props.contents.sections.map((section) => {
            return (
              <Section
                title={section.title}
                image={section.image}
                href={section.href}
                description={section.description}
              ></Section>
            );
          })}
        </div>
      </div>
      </div>
      </div>
    </div>
  </div>)
}