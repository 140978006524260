export default function ModeSwitcher(props) {
    return(<><div className="container mobile-invisible">
        <h2 className="position">Mode</h2>
        <div className="flex-row">
          {props.contents.modes.map((mode) => {
            return (
              <button
                type="button"
                onClick={() => {
                  localStorage.setItem("mode", JSON.stringify(mode))
                  props.setAgent(mode);
                }}
                className={"btn m-1" + (mode.disabled ? " strike-through" : "") + ( (mode.name===props.agent.name) ?" btn-primary":" btn-secondary")}
                disabled={mode.disabled ? "disabled" : ""}
              >
                {mode.display}
              </button>
            );
          })}
        </div>
      </div>
      <div className="container mobile-only dropdown-mobile">
      <h2 className="position">Mode</h2>
      <div className="dropdown dropdown-mobile">
              <button className="btn btn-secondary dropdown-toggle dropdown-mobile" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                MODE
              </button>
              <ul className="dropdown-menu">
                {props.contents.modes.map(mode=>{
                  return(<li><a className="dropdown-item" onClick={() => {
                    localStorage.setItem("mode", JSON.stringify(mode))
                    props.setAgent(mode);
                  }}>{mode.display}</a></li>)
                })}
                
              </ul>
            </div>
      </div>
      </>)
}