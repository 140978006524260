import {toast} from "react-toastify"

export default function Item(props) {
    return(
        
        <div className="section section-grid">
            <div className="section-text">
           <h5 style={{fontWeight: 800}}>{props.title}</h5>
            <h5>{props.description}</h5>
            </div>
            <img src={props.image} alt={props.title} className="section-img img-fluid"/>
            <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Open Link
            </button>
            <ul className="dropdown-menu">
                <li><a className="dropdown-item" href={props.href} target="_blank">Open Link</a></li>
                <li><a className="dropdown-item" onClick={() =>  {navigator.clipboard.writeText(props.href.toString()); toast("Copied to clipboard!", {type:"success"})}}>Copy Link</a></li>
            </ul>
            </div>
            
        </div>
        
    )
}