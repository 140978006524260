import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Navbar from "./components/navbar";
import contents from "./contents.json";
import { useContext, useEffect, useState, useGlobalState } from "react";
import "./App.css";
import { HashRouter, Route, Routes, Link } from "react-router-dom";
import Home from "./pages/Home";
import Brands from "./pages/Brands";
import Items from "./pages/Items";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FloatingButton from "./components/floating_button";
import Search from "./pages/Search";
import Notification from "./components/notifications";

function makeObservable(target) {
  let listeners = []; // initial listeners can be passed an an argument aswell
  let value = target;

  function get() {
    return value;
  }

  function set(newValue) {
    if (value === newValue) return;
    value = newValue;
    listeners.forEach((l) => l(value));
  }

  function subscribe(listenerFunc) {
    listeners.push(listenerFunc);
    return () => unsubscribe(listenerFunc); // will be used inside React.useEffect
  }

  function unsubscribe(listenerFunc) {
    listeners = listeners.filter((l) => l !== listenerFunc);
  }

  return {
    get,
    set,
    subscribe,
  };
}

const agentStore = makeObservable(contents.modes[0]);
const modes = contents.modes;

function App() {
  const [dismissedNotification, setDismissedNotification] = useState(false);
  const [agent, setAgent] = useState(agentStore.get());

  const getAllItems = () => {
    var allItems = [];
    contents.sections.forEach((section) => {
      section.brands.forEach((brand) => {
        if (brand.items) {
          brand.items.forEach((item) => {
            allItems.push(item);
          });
        }
      });
    });
    return allItems;
  };
  useEffect(() => {
    document.title = contents.name;
    const mode = JSON.parse(localStorage.getItem("mode"));
    const dismissedNotification = JSON.parse(
      localStorage.getItem(`notification-dismissed-${contents.notification_id}`)
    );
    if (mode) {
      setAgent(mode);
    }

    if (dismissedNotification) {
      setDismissedNotification(
        localStorage.getItem(
          `notification-dismissed-${contents.notification_id}`
        )
      );
    }

    return agentStore.subscribe(setAgent);
  }, []);
  return (
    <>
      <HashRouter>
        <Navbar
          logoLink="/"
          name={contents.name}
          pages={contents.sections}
          mode={agent}
          modes={modes}
          setAgent={setAgent}
        >
          {" "}
          {!dismissedNotification ? (
            <Notification
              title={contents.notification_title}
              description={contents.notification_description}
              setDismissed={setDismissedNotification}
              id={contents.notification_id}
            />
          ) : (
            ""
          )}
        </Navbar>
        <ToastContainer
          autoClose={1000}
          position={"top-center"}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
        <FloatingButton image={"/assets/discord.png"} href={contents.discord} />

        <p className="top-distance" />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                contents={contents}
                items={getAllItems()}
                agent={agent}
                setAgent={setAgent}
              />
            }
          />
          <Route
            path="/search"
            element={<Search items={getAllItems()} mode={agent} />}
          />
          {contents.sections.map((section) => {
            return (
              <Route path={section.href.replace("/#", "")}>
                <Route
                  index
                  element={
                    <Brands
                      brands={section.brands.sort(function (a, b) {
                        if (a.name.toLowerCase() < b.name.toLowerCase())
                          return -1;
                        if (a.name.toLowerCase() > b.name.toLowerCase())
                          return 1;
                        return 0;
                      })}
                      contents={contents}
                      agent={agent}
                      setAgent={setAgent}
                      title={section.title}
                      section={section}
                    />
                  }
                />
                {section.brands
                  ? section.brands.map((brand) => {
                      return (
                        <Route
                          path={brand.href.replace(`/#`, "")}
                          element={
                            <Items
                              agent={agent}
                              mode={agent}
                              title={brand.title}
                              items={brand.items}
                              setAgent={setAgent}
                              contents={contents}
                            />
                          }
                        />
                      );
                    })
                  : ""}
              </Route>
            );
          })}
        </Routes>
      </HashRouter>
      <br />
    </>
  );
}

export default App;
