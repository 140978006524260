import Section from "./section"

export default function Collapsable(props) {
    return (
        <div class="accordion">
            
        {props.parentBrands.sort(function (a, b) {
                        if (a.toLowerCase() < b.toLowerCase())
                          return -1;
                        if (a.toLowerCase() > b.toLowerCase())
                          return 1;
                        return 0;
                      }).map(brand=>{
            return(<>
                    <h2 className="accordion-header accordion-flush">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+brand} aria-expanded="false" aria-controls={"collapse"+brand}>
                        {brand.toUpperCase()}
                    </button>
                    </h2>
                    <div id={"collapse"+brand} className="accordion-collapse collapse">
                    <div className="accordion-body sections">
                        {props.brands.map(cathegory=>{
                            if (cathegory.parent_brand==brand) {
                                return(<Section title={cathegory.title} description={cathegory.description} image={cathegory.image} href={cathegory.href}/>)
                            }
                        })}
                    </div>
                    </div>
                
        
              
            </>)
        })}
        </div>
     
    )
}