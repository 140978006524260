export default function Navbar(props) {
    return (<>
      <div className="fixed-top">
        <nav className="navbar navbar-expand-lg bg-blurry">
        <div className="container-fluid">
          <a className="navbar-brand" href={props.logoLink}>{props.name}</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
          <span>PAGES:</span>
            <ul className="navbar-nav navbar-ul">
            <li className="nav-item">
                    <a className="nav-link" href="/">Home</a>
                  </li>
              {props.pages.map(page=>{
                return(<li className="nav-item">
                    <a className="nav-link" href={page.href}>{page.title}</a>
                  </li>)
              })}
              </ul>
              <span>MODE:</span>
              <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {props.mode.display}
              </button>
              <ul className="dropdown-menu">
                {props.modes.map(mode=>{
                  return(<li><a className="dropdown-item" onClick={() => {
                    localStorage.setItem("mode", JSON.stringify(mode))
                    props.setAgent(mode);
                  }}>{mode.display}</a></li>)
                })}
                
              </ul>
            </div>
              
              
            
          </div>
        </div>
      </nav>{props.children}</div></>)
}