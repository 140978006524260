export default function Section(props) {
    return(
        <a href={props.href}>
        <div className="section section-grid2">
            <div className="section-text2">
            <h4>{props.title}</h4>
            <h6>{props.description}</h6>
            </div>
            <img src={props.image} alt={props.title} className="section-img"/>
            
        </div>
        </a>
    )
}